<style>
  .v-text-field input {
    font-size: 0.8em;
  }
</style>

<template>
  <v-row>
    <v-col
      cols="12"
      class="pl-6 pt-2 pb-6"
      style="
        font-family: Poppins;
        font-weight: bold;
        font-size: Large;
        color: #4676C8;
      "
    >
      Evaluation Criteria
    </v-col>
  </v-row>
  <v-container
    fluid
    fill-height
    class="d-flex flex-column pa-0 ma-0"
    >
        <v-sheet
        rounded
        :elevation="3"
        style="border-radius:30px"
        class="mb-3"
        >
        <v-form @submit.prevent>
          <v-row no-gutters dense>
            <v-col
                justify="center"
                cols="2"
                class="d-flex align-center justify-center pa-1"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >PRINCIPLE</v-col
            >
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center pa-1"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >AVERAGE (R1/R2)</v-col
            >
            <v-col justify="center" cols="8">
                <v-row no-gutters dense>
                <v-col
                    justify="center"
                    cols="6"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >CRITERIA</v-col
                >
                <v-col
                    justify="center"
                    cols="2"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >R1 AS-IS</v-col
                >
                <v-col
                    justify="center"
                    cols="1"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >R1 SCORE</v-col
                >
                <v-col
                    justify="center"
                    cols="2"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >R2 AS-IS</v-col
                >
                <v-col
                    justify="center"
                    cols="1"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >R2 SCORE</v-col
                >
                </v-row>
            </v-col>
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center pa-1 pr-2"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >GO/NO GO</v-col
            >
          </v-row>
          <v-row
            no-gutters
            dense
            v-for="item in categories"
            :key="item.id"
            >
            <v-col
                justify="center"
                cols="2"
                class="d-flex align-center justify-center"
                :style="'background: ' + item.color1"
                style="
                font-family: Inter;
                font-weight: bold;
                font-size: smaller;
                "
            >
                {{ item.categoryname }}
            </v-col>
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center"
                :style="'background: ' + item.color2"
            >
              <v-card
                density="compact"
                variant="flat"
                :text="'R1:' + item.average1"
                color="transparent"
                height="98%"
                width="90%"
                justify="center"
                class="d-flex align-center text-center"
              />
              <v-card
                density="compact"
                variant="flat"
                :text="'R2:' + item.average2"
                color="transparent"
                height="98%"
                width="90%"
                justify="center"
                class="d-flex align-center text-center"
              />
            </v-col>
            <v-col cols="8">
              <v-row
                no-gutters
                dense
                v-for="crit in criteria"
                :key="crit.id"
              >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="6"
                    class="d-flex align-center pa-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >{{ crit.text }}</v-col
                >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="2"
                    class="d-flex align-center justify-center pa-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >
                    {{ crit.asis1 }}
                    <v-tooltip v-if="crit.details1!=''"
                      activator="parent"
                      location="bottom"
                    >Details: {{ crit.details1 }}</v-tooltip></v-col
                >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="1"
                    class="d-flex align-center justify-center pa-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >{{ crit.score1 }}</v-col
                >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="2"
                    class="d-flex align-center justify-center pa-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >
                    {{ crit.asis2 }}
                    <v-tooltip v-if="crit.details2!=''"
                      activator="parent"
                      location="bottom"
                    >Details: {{ crit.details2 }}</v-tooltip></v-col
                >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="1"
                    class="d-flex align-center justify-center pa-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >{{ crit.score2 }}</v-col
                >
                <v-divider v-if="crit.category == item.id"></v-divider>
              </v-row>
            </v-col>
            <v-col
              justify="center"
              cols="1"
              class="align-center justify-center ma-0 pr-6"
            >
            <v-container fluid fill-height class='d-flex flex-column ma-0 pa-0'>
              <v-row>
                <v-col class="ma-0 mt-3 pa-0">
                  <v-container fluid fill-height class="ma-0 pa-0">
                    <v-card
                      :id="item.categoryid+'_go-nogo1'"
                      :name="item.categoryid+'go-nogo1'"
                      variant="flat"
                      :color="item.go1 ? '#CEF1E8' : '#F2DFE1'"
                      :text="item.go1 ? ' R1:GO' : 'R1:NO-GO'"
                      height="100%"
                      width="100%"
                      justify="center"
                      class="d-flex align-center text-center ma-0"
                      style="
                      font-family: Inter;
                      font-weight: bold;
                      font-size: smaller;
                      "
                    />
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="ma-0 pa-0">
                  <v-container fluid fill-height class="ma-0 pa-0">
                    <v-card
                      :id="item.categoryid+'_go-nogo2'"
                      :name="item.categoryid+'go-nogo2'"
                      variant="flat"
                      :color="item.go2 ? '#CEF1E8' : '#F2DFE1'"
                      :text="item.go2 ? ' R2:GO' : 'R2:NO-GO'"
                      height="100%"
                      width="100%"
                      justify="center"
                      class="d-flex align-center text-center ma-0"
                      style="
                      font-family: Inter;
                      font-weight: bold;
                      font-size: smaller;
                      "
                    />
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

            </v-col>
          </v-row>
        </v-form>
        </v-sheet>
    </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useAppStore } from '@/stores/app';
import axios from 'axios';

export default {
  setup() {
    const app = useAppStore();
    const categories = ref([]);
    const criteria = ref([]);
    let scores = [];

    const fetchInstanceDetails = async () => {
      //console.log('in fetchInstanceDetails');
      if (app.instanceId && app.activeuserId) {
        try {
          // Fetch instance details
          const response = await axios.get(app.baseUrl + `/api/scorecardinstances/${app.instanceId}/detail/`);
          const data = response.data;
          categories.value = data.campaign_scorecard.template.scorecard_categories;
          scores = data.campaign_scorecard.template.scorecard_questions;
          // Initialize scores
          scores.forEach(question => {
            const criteriaData = {
              id: question.id,
              text: question.text,
              score1: 0,
              asis1: "",
              details1: "",
              score2: 0,
              asis2: "",
              details2: "",
              category: question.category,
              questionid: question.questionid,
            };
            criteria.value.push(criteriaData);
            //console.log(criteriaData);
          });
          await fetchScorecardScores1();
          await fetchScorecardScores2();
          updateAverages();
        } catch (error) {
          console.error('Error fetching instance details:', error);
          // Handle error appropriately
        }
      }
    };

    const fetchScorecardScores1 = async () => {
      //console.log('in fetchScorecardScores1');
      if (app.instanceId && app.activeuserId) {
        try {
          const response1 = await axios.get(app.baseUrl + `/api/scorecardscoresbulk/?user_id=${app.reviewer1Id}&instance_id=${app.instanceId}`);
          criteria.value.forEach(question => {
            const questionData = response1.data.find(data => data.questionid === question.id);
            if (questionData) {
              question.score1 = questionData.score;
              question.asis1 = questionData.asis;
              question.details1 = questionData.details;
            }
          });
        } catch (error) {
          console.error('Error fetching scorecard scores:', error);
          // Handle error appropriately
        }
      }
    };

    const fetchScorecardScores2 = async () => {
      //console.log('in fetchScorecardScores2');
      if (app.instanceId && app.activeuserId) {
        try {
          const response2 = await axios.get(app.baseUrl + `/api/scorecardscoresbulk/?user_id=${app.reviewer2Id}&instance_id=${app.instanceId}`);
          criteria.value.forEach(question => {
            const questionData = response2.data.find(data => data.questionid === question.id);
            if (questionData) {
              question.score2 = questionData.score;
              question.asis2 = questionData.asis;
              question.details2 = questionData.details;
            }
          });
        } catch (error) {
          console.error('Error fetching scorecard scores:', error);
          // Handle error appropriately
        }
      }
    };

    const updateAverages = () => {
      // Runs when numerical valus change.
      //console.log('in updateAverages');
      const threshold = 3.5;
      var savg1=0;
      var savg2=0;
      categories.value.forEach(category => {
        var avg1=0;
        var avg2=0;
        var count=0;
        category.go1=true;
        category.go2=true;
        criteria.value.filter((item) => item.category==category.id).forEach(i => {
          let tmpi1=parseFloat(i.score1);
          let tmpi2=parseFloat(i.score2);
          count++;
          if (tmpi1 > 5)
          {
            i.score1=5;
          }
          else if (tmpi1 < 0)
          {
            i.score1=0;
          }
          if (tmpi2 > 5)
          {
            i.score2=5;
          }
          else if (tmpi2 < 0)
          {
            i.score2=0;
          }
          avg1+=tmpi1;
          avg2+=tmpi2;

          (tmpi1 >= threshold ) ? i.go1=true : i.go1=false;
          (tmpi2 >= threshold ) ? i.go2=true : i.go2=false;
          (! i.go1) ? category.go1=false : "";
          (! i.go2) ? category.go2=false : "";
        });
        category.average1=(avg1/count).toFixed(2);
        category.average2=(avg2/count).toFixed(2);
        savg1+=Number(category.average1);
        savg2+=Number(category.average2);
      });
      app.t_average=(savg1/categories.value.length).toFixed(2);
      app.t_average2=(savg2/categories.value.length).toFixed(2);

      (app.t_average >= threshold) ? app.gonogo=true : app.gonogo=false;
    };

    onMounted(() => {
      // Fetch instance details on component mount
      //console.log('in onMounted ',app.instanceId,app.activeuserId);
      if (app.instanceId > 0 && app.activeuserId) {
        fetchInstanceDetails();
      }
    });

    return {
      app,
      categories,
      criteria,
      fetchInstanceDetails,
      fetchScorecardScores1,
      fetchScorecardScores2,
      updateAverages,
    };
  },
};
</script>