import axios from 'axios';
import { useAuthStore } from '@/stores/auth';

// Add a request interceptor to attach the access token to outgoing requests
axios.interceptors.request.use(
  (config) => {
    // Add the access token to the Authorization header
    const authStore = useAuthStore();
    config.headers.Authorization = `Bearer ${authStore.access}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
});

// Add a response interceptor to handle token expiration or invalidation
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const authStore = useAuthStore();
    // If the error status is 401 (Unauthorized) and there's no refresh token, logout the user
    if (error.response.status === 401 && !authStore.refresh) {
      authStore.logout();
      // Redirect the user to the login page or handle the logout action as needed
    }
    // If the error status is 401 and there's a refresh token, attempt to refresh the access token
    else if (error.response.status === 401 && authStore.refresh) {
      try {
        // Perform token refresh (You'll need to implement this logic)
        await authStore.refreshAccessToken();
        // Retry the original request with the new access token
        return axios(originalRequest);
      } catch (refreshError) {
        // If refresh token is also invalid, logout the user
        authStore.logout();
        // Redirect the user to the login page or handle the logout action as needed
      }
    }
    return Promise.reject(error);
});

export default axios;