<template>
  <v-row>
    <v-col
      cols="12"
      class="pl-6 pt-2 pb-6"
      style="
        font-family: Poppins;
        font-weight: bold;
        font-size: Large;
        color: #4676C8;
      "
    >
      Customer Journeys
    </v-col>
  </v-row>
  <v-sheet
    rounded
    :elevation="3"
    style="border-radius:30px"
    class="mb-3"
  >
    <v-container>
      <v-row>
        <v-col cols="12"
          class="pb-0"
        >
          <v-combobox
            v-model="selectedInstance"
            :items="instanceOptions"
            label="Select Customer Journey"
            variant="outlined"
            density="compact"
            clearable
            @update:modelValue="fetchInstanceDetails()"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  <v-sheet
    rounded
    :elevation="3"
    style="border-radius:30px"
  >
    <v-container v-if="selectedInstance">
      <v-row>
        <v-col cols="6"
          class="pt-3 pb-0"
        >
          <v-text-field
            v-model="app.campaignName"
            label="Customer Experience Campaign"
            variant="outlined"
            density="compact"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="app.description"
            label="Customer Journey Instance"
            variant="outlined"
            density="compact"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6"
          class="pt-3 pb-0"
        >
          <v-text-field
            v-model="app.admin"
            label="Scorecard Administrator"
            variant="outlined"
            density="compact"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="app.reviewer1"
            label="Scorecard Reviewer 1"
            variant="outlined"
            density="compact"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="app.reviewer2"
            label="Scorecard Reviewer 2"
            variant="outlined"
            density="compact"
            readonly
          ></v-text-field>
        </v-col>
        <!-- Add more read-only fields for displaying instance details -->
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { ref } from 'vue';
import { useAppStore } from '@/stores/app';
import axios from '@/stores/axiosConfig';

export default {
  setup() {
    const app = useAppStore();
    const selectedInstance = ref(null);
    const instanceOptions = ref([]);

    const fetchInstanceDetails = async () => {
      if (selectedInstance.value) {
        app.loading = true;
        try {
          const response = await axios.get(app.baseUrl + `/api/scorecardinstances/${selectedInstance.value.id}/detail/`);
          const data = response.data;
          app.instanceId = selectedInstance.value.id;
          app.campaignScorecardId = data.campaign_scorecard.id;
          app.campaignName = data.campaign_scorecard.campaign.campaignname;
          app.templateId = data.campaign_scorecard.template.id;
          app.adminId = data.campaign_scorecard.adminuser.id;
          app.admin = data.campaign_scorecard.adminuser.username;
          app.reviewer1Id = data.reviewer1.id;
          app.reviewer1 = data.reviewer1.username;
          app.reviewer2Id = data.reviewer2.id;
          app.reviewer2 = data.reviewer2.username;
          app.description = data.description;
          app.status = data.status;
          app.userOptions = [];
          app.userOptions.push({title: "First Reviewer", value: app.reviewer1Id});
          app.userOptions.push({title: "Second Reviewer", value: app.reviewer2Id});
          app.userOptions.push({title: "Administrator", value: app.adminId});
        } catch (error) {
          console.error('Error fetching instance details:', error);
          app.instanceId = null;
          app.campaignScorecardId = null;
          app.campaignName = null;
          app.templateId = null;
          app.adminId = null;
          app.admin = null;
          app.reviewer1Id = null;
          app.reviewer1 = null;
          app.reviewer2Id = null;
          app.reviewer2 = null;
          app.description = null;
          app.status = null;
          app.userOptions = [];
        } finally {
          app.loading = false;
        }
      } else {
        app.instanceId = null;
        app.campaignScorecardId = null;
        app.campaignName = null;
        app.templateId = null;
        app.adminId = null;
        app.admin = null;
        app.reviewer1Id = null;
        app.reviewer1 = null;
        app.reviewer2Id = null;
        app.reviewer2 = null;
        app.campaignName = null;
        app.description = null;
        app.status = null;
        app.userOptions = [];
        app.loading = false;
      }
    };

    const fetchInstanceOptions = async () => {
      app.loading = true;
      try {
        const response = await axios.get(app.baseUrl + '/api/scorecardinstances/detail/');
        instanceOptions.value = response.data.map(instance => ({
          id: instance.id,
          title: `${instance.campaign_scorecard.campaign.campaignname} - ${instance.description}`,
        }));
      } catch (error) {
        console.error('Error fetching instance options:', error);
      } finally {
        app.loading = false;
      }
    };

    return {
      app,
      selectedInstance,
      instanceOptions,
      fetchInstanceDetails,
      fetchInstanceOptions,
    };
  },
  mounted() {
    this.fetchInstanceOptions();
  },
};
</script>

<style>
/* Add your custom styles here */
</style>