import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    loading: ref(null),
    instanceId: ref(null),
    campaignScorecardId: ref(null),
    campaignName: ref(null),
    activeuserId: ref(null),
    activeuserType: ref(null),
    templateId: ref(null),
    adminId: ref(null),
    admin: ref(null),
    reviewer1Id: ref(null),
    reviewer1: ref(null),
    reviewer2Id: ref(null),
    reviewer2: ref(null),
    description: ref(null),
    status: ref(null),
    userOptions: ref([]),
    gonogo: ref(false),
    gonogo2: ref(false),
    t_average: ref(null),
    t_average2: ref(null),
    dirty: false,
    baseUrl: "https://scorecard.slpg.eu",
  }),
  actions: {
    // app-related actions
  },
});
