import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore({
  id: 'auth',
  // Define state properties
  state: () => ({
    isLoggedIn: false,
    refresh: null,
    access: null,
  }),
  // Define actions
  actions: {
    // Login action
    async login(baseUrl, credentials) {
      try {
        // Send login request to API
        const response = await axios.post(baseUrl + '/api/token/', credentials.value, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // Update store state with token and user data
        this.refresh = response.data.refresh;
        this.access = response.data.access;
        this.isLoggedIn = true;
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },
    // Logout action
    logout() {
      // Clear token and user data
      this.refresh = null;
      this.access = null;
      this.isLoggedIn = false;
    },
  },
});