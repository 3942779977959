<style>
  .v-text-field input {
    font-size: 0.8em;
  }
</style>

<template>
  <v-row>
    <v-col
      cols="12"
      class="pl-6 pt-2 pb-6"
      style="
        font-family: Poppins;
        font-weight: bold;
        font-size: Large;
        color: #4676C8;
      "
    >
      Evaluation Criteria
    </v-col>
  </v-row>
  <v-container
    fluid
    fill-height
    class="d-flex flex-column pa-0 ma-0"
    >
        <v-sheet
        rounded
        :elevation="3"
        style="border-radius:30px"
        class="mb-3"
        >
        <v-form @submit.prevent>
          <v-row
            class="ma-2"
            v-if="app.activeuserId && app.instanceId"
            >
            <v-col
                cols="4"
            />
            <v-col
                cols="2"
            >
              <v-dialog width="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    type="submit"
                    color="error"
                    block
                  >Clear</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Clear all data?">
                    <v-card-text>
                      If you continue, all unsaved changes will be lost and all the fields will be cleared.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Continue"
                        @click="isActive.value = false; clearValues()"
                      ></v-btn>
                      <v-btn
                        text="Cancel"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col
                cols="2"
            >
              <v-dialog width="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    type="submit"
                    color="warning"
                    block
                  >Reset</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Reset all data to last saved values?">
                    <v-card-text>
                      If you continue, all unsaved changes will be lost and all the fields will be reset to the last saved values.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Continue"
                        @click="isActive.value = false; resetValues()"
                      ></v-btn>
                      <v-btn
                        text="Cancel"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col
                cols="2"
            >
                <v-btn
                type="submit"
                color="#5277C3"
                block
                @click="updateScorecardScores()"
                >Save Draft</v-btn>
            </v-col>
            <v-col
                cols="2"
            >
                <v-btn
                type="submit"
                color="#56C351"
                block
                @click="updateScorecardScores()"
                >Review Complete</v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col
                justify="center"
                cols="2"
                class="d-flex align-center justify-center pa-1"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >PRINCIPLE</v-col
            >
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center pa-1"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >AVERAGE</v-col
            >
            <v-col justify="center" cols="8">
                <v-row no-gutters dense>
                <v-col
                    justify="center"
                    cols="6"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >CRITERIA</v-col
                >
                <v-col
                    justify="center"
                    cols="2"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >AS IS</v-col
                >
                <v-col
                    justify="center"
                    cols="1"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >SCORING</v-col
                >
                <v-col
                    justify="center"
                    cols="3"
                    class="d-flex align-center justify-center pa-1"
                    style="
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: smaller;
                    color: #666666;
                    "
                    >DETAILS</v-col
                >
                </v-row>
            </v-col>
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center pa-1 pr-2"
                style="
                font-family: Montserrat;
                font-weight: bold;
                font-size: smaller;
                color: #666666;
                "
                >GO/NO GO</v-col
            >
          </v-row>
          <v-row
            no-gutters
            dense
            v-for="item in categories"
            :key="item.id"
            >
            <v-col
                justify="center"
                cols="2"
                class="d-flex align-center justify-center"
                :style="'background: ' + item.color1"
                style="
                font-family: Inter;
                font-weight: bold;
                font-size: smaller;
                "
            >
                {{ item.categoryname }}
            </v-col>
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center"
                :style="'background: ' + item.color2"
            >
                <v-card
                density="compact"
                variant="flat"
                :text="item.average"
                color="transparent"
                height="98%"
                width="90%"
                justify="center"
                class="d-flex align-center text-center"
                />
            </v-col>
            <v-col cols="8">
              <v-row
                no-gutters
                dense
                v-for="crit in criteria"
                :key="crit.id"
              >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="6"
                    class="d-flex align-center pl-3"
                    style="
                    font-family: Inter;
                    font-weight: normal;
                    font-size: small;
                    "
                    >{{ crit.text }}</v-col
                >
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="2"
                    class="d-flex align-center pa-1"
                >
                    <v-text-field
                    v-model.lazy="crit.asis"
                    :id="item.categoryid+'_'+crit.questionid+'_asis'"
                    :name="item.categoryid+'_'+crit.questionid+'_asis'"
                    density="compact"
                    hide-details
                    variant="outlined"
                    single-line
                    required
                    base-color="blue-darken-2"
                    color="blue-darken-4"
                    />
                </v-col>
                <v-col
                    v-if="crit.category == item.id"
                    justify="center"
                    cols="1"
                    class="d-flex align-center pa-1"
                >
                    <v-text-field
                    @change="updateAverages()"
                    v-model.number="crit.score"
                    :id="item.categoryid+'_'+crit.questionid+'_score'"
                    :name="item.categoryid+'_'+crit.questionid+'_score'"
                    density="compact"
                    hide-details
                    variant="outlined"
                    single-line
                    required
                    type="number"
                    base-color="blue-darken-2"
                    color="blue-darken-4"
                    />
                </v-col>
                <v-col
                    v-if="crit.category == item.id"
                    cols="3"
                    class="pa-1"
                >
                    <v-text-field
                    v-model.lazy="crit.details"
                    :id="item.categoryid+'_'+crit.questionid+'_details'"
                    :name="item.categoryid+'_'+crit.questionid+'_details'"
                    density="compact"
                    hide-details
                    variant="outlined"
                    single-line
                    base-color="blue-darken-2"
                    color="blue-darken-4"
                    height="30px"
                    style="pa-3"
                    />
                </v-col>
                <v-divider v-if="crit.category == item.id"></v-divider>
              </v-row>
            </v-col>
            <v-col
                justify="center"
                cols="1"
                class="d-flex align-center justify-center ma-0 pa-0"
                >
                <v-card
                    :id="item.categoryid+'_go-nogo'"
                    :name="item.categoryid+'go-nogo'"
                    variant="flat"
                    :color="item.go ? '#CEF1E8' : '#F2DFE1'"
                    :text="item.go ? '  GO' : 'NO-GO'"
                    height="98%"
                    width="90%"
                    justify="center"
                    class="d-flex align-center text-center ma-0"
                    style="
                    font-family: Inter;
                    font-weight: bold;
                    font-size: smaller;
                    "
                />
                </v-col
            >
          </v-row>
          <v-row
            class="ma-2"
            v-if="app.activeuserId && app.instanceId"
            >
            <v-col
                cols="4"
            />
            <v-col
                cols="2"
            >
              <v-dialog width="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    type="submit"
                    color="error"
                    block
                  >Clear</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Clear all data?">
                    <v-card-text>
                      If you continue, all unsaved changes will be lost and all the fields will be cleared.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Continue"
                        @click="isActive.value = false; clearValues()"
                      ></v-btn>
                      <v-btn
                        text="Cancel"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col
                cols="2"
            >
              <v-dialog width="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    type="submit"
                    color="warning"
                    block
                  >Reset</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Reset all data to last saved values?">
                    <v-card-text>
                      If you continue, all unsaved changes will be lost and all the fields will be reset to the last saved values.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Continue"
                        @click="isActive.value = false; resetValues()"
                      ></v-btn>
                      <v-btn
                        text="Cancel"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col
                cols="2"
            >
                <v-btn
                type="submit"
                color="#5277C3"
                block
                @click="updateScorecardScores()"
                >Save Draft</v-btn>
            </v-col>
            <v-col
                cols="2"
            >
                <v-btn
                type="submit"
                color="#56C351"
                block
                @click="updateScorecardScores()"
                >Review Complete</v-btn>
            </v-col>
          </v-row>
        </v-form>
        </v-sheet>
    </v-container>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useAppStore } from '@/stores/app';
import axios from 'axios';

export default {
  setup() {
    const app = useAppStore();
    const categories = ref([]);
    const criteria = ref([]);
    const scores = [];

    let initialDataLoading = true;

    const fetchInstanceDetails = async () => {
      //console.log('in fetchInstanceDetails');
      if (app.instanceId && app.activeuserId) {
        if (app.dirty) {
          // Trigger confirmation dialog
          if (!confirm('All unsaved changes will be lost. Do you want to continue?')) {
              return;
          }
        }
        try {
          app.dirty=false;
          initialDataLoading = true;
          // Fetch instance details
          const response = await axios.get(app.baseUrl + `/api/scorecardinstances/${app.instanceId}/detail/`);
          const data = response.data;
          categories.value = data.campaign_scorecard.template.scorecard_categories;
          criteria.value = data.campaign_scorecard.template.scorecard_questions;
          // Initialize scores
          scores.value = criteria.value.map(question => ({
            id: null,
            score: 0,
            asis: "",
            details: "",
            user: app.activeuserId.value,
            instanceid: app.instanceId,
            questionid: question.id
          }));
          await fetchScorecardScores();
          resetValues();
        } catch (error) {
          console.error('Error fetching instance details:', error);
          // Handle error appropriately
        }
      }
    };

    const fetchScorecardScores = async () => {
      //console.log('in fetchScorecardScores');
      if (app.instanceId && app.activeuserId) {
        try {
          const response = await axios.get(app.baseUrl + `/api/scorecardscoresbulk/?user_id=${app.activeuserId.value}&instance_id=${app.instanceId}`);
          scores.value.forEach(score => {
            const scoreData = response.data.find(data => data.questionid === score.questionid);
            if (scoreData) {
              score.id = scoreData.id;
              score.score = scoreData.score;
              score.asis = scoreData.asis;
              score.details = scoreData.details;
            }
          });
        } catch (error) {
          console.error('Error fetching scorecard scores:', error);
          // Handle error appropriately
        }
      }
    };

    const resetValues = () => {
      // Resets the data to those we got from the DB.
      //console.log('in resetValues');

      //clearValues();
      scores.value.forEach(score => {
        criteria.value.find((element) => element.id == score.questionid).score=score.score;
        criteria.value.find((element) => element.id == score.questionid).asis=score.asis;
        criteria.value.find((element) => element.id == score.questionid).details=score.details;
      });
      initialDataLoading = true;
      updateAverages();
      initialDataLoading = true;
      app.dirty=false;
    };

    const clearValues = () => {
      // Clears the values visible on the form, but not the scores hash.
      //console.log('in clearValues');
      criteria.value.forEach(question => {
        question.score = 0;
        question.asis = "";
        question.details = "";
      });
      updateAverages();
      app.dirty=true;
    };

    const updateAverages = () => {
      // Runs when numerical valus change.
      //console.log('in updateAverages');
      const threshold = 3.5;
      var savg=0;
      categories.value.forEach(category => {
        var avg=0;
        var count=0;
        category.go=true;
        criteria.value.filter((item) => item.category==category.id).forEach(i => {
          count++;
          let tempi=parseFloat(i.score);
          if (tempi > 5)
          {
            i.score=5;
          }
          else if (tempi < 0)
          {
            i.score=0;
          }
          avg+=tempi;

          (tempi >= threshold ) ? i.go=true : i.go=false;
          (! i.go) ? category.go=false : "";
        });
        category.average=(avg/count).toFixed(2);
        savg+=Number(category.average);
      });
      app.t_average=(savg/categories.value.length).toFixed(2);

      (app.t_average >= threshold) ? app.gonogo=true : app.gonogo=false;
    };

    const updateScores = () => {
      // While the app is running , the scores hash holds the data as we got them from the DB, while the questions hash
      // holds the live data. Before we save, we need to transfer the data from questions to scores for them to be saved.
      //console.log('in updateScores');
      //console.log(criteria);
      criteria.value.forEach(question => {
        scores.value.find((element) => element.questionid == question.id).score=question.score;
        scores.value.find((element) => element.questionid == question.id).asis=question.asis;
        scores.value.find((element) => element.questionid == question.id).details=question.details;
      });
      //console.log(scores);
    };

    const updateScorecardScores = async () => {
      //console.log('in updateScorecardScores');
      updateScores();
      try {
        //console.log(scores)
        const response = await axios.put(app.baseUrl + `/api/scorecardscoresbulk/update/?user_id=${app.activeuserId.value}&instance_id=${app.instanceId}`, scores.value);
        console.log('Scores updated successfully:', response.data);
        // Optionally, perform any actions after successful update
        app.dirty=false;
      } catch (error) {
        console.error('Error updating scores:', error);
        // Handle error appropriately
      }
    };

    onMounted(() => {
      // Fetch instance details on component mount
      //console.log('in onMounted ',app.instanceId,app.activeuserId);
      initialDataLoading=true;
      if (app.instanceId > 0 && app.activeuserId) {
        fetchInstanceDetails();
      }
    });

    // Watch for changes in activeuserId and selectedInstanceId
    watch([() => app.activeuserId, () => app.instanceId], ([newActiveuserId, newSelectedInstanceId], [oldActiveuserId, oldSelectedInstanceId]) => {
      //console.log('in watch ids ',app.instanceId,newActiveuserId);
      if (newActiveuserId && newActiveuserId.value > 0 && newSelectedInstanceId > 0 && (newActiveuserId !== oldActiveuserId || newSelectedInstanceId !== oldSelectedInstanceId)) {
        fetchInstanceDetails();
      }
    });

    // Watch for changes in criteria values and set dirty to true
    watch(criteria, () => {
      //console.log('in watch criteria, initial data loading ',initialDataLoading,app.dirty);
      if (initialDataLoading) {
        initialDataLoading=false;
      }
      else
      {
        app.dirty = true;
      }
    }, { deep: true });

    return {
      app,
      categories,
      criteria,
      scores,
      fetchInstanceDetails,
      fetchScorecardScores,
      resetValues,
      clearValues,
      updateAverages,
      updateScores,
      updateScorecardScores,
    };
  },
};
</script>