<template>
  <v-app>
    <!-- Header Section -->
    <v-system-bar color="black" height="60">
      <v-row justify="start">
        <v-col align-self="center" cols="1">
          <v-img
            :src="require('@/assets/pwc.png')"
            class="ma-0"
            contain
            height="40"
          />
        </v-col>
        <v-col cols="6"
          class="mt-0"  
          style="
            font-family: Poppins;
            font-weight: bold;
            font-size: Large;
            color: #FFFFFF;
          "
        >
          <v-row v-if="auth.isLoggedIn">
            <v-col cols="3"
              style="
                font-family: Poppins;
                font-weight: normal;
                font-size: Large;
                color: #FFFFFF;
              "
              class="mt-3"
            >
              Welcome, {{ formData.username }}
            </v-col>
            <v-col cols="7">
            </v-col>
            <v-col cols="2">
              <v-btn
                type="submit"
                color="#333333"
                variant="flat"
                block
                @click="logout"
                >Logout</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="5">
              <v-text-field
              v-model="formData.username"
              variant="solo-filled"
              density="compact"
              hide-details
              single-line
              required
              label="Username"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
              v-model="formData.password"
              variant="solo-filled"
              density="compact"
              hide-details
              single-line
              required
              label="Password"
              type="Password"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                type="submit"
                color="#666666"
                variant="flat"
                block
                @click="login"
                >Login</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4"
          class="mt-3"  
          style="
            font-family: Poppins;
            font-weight: bold;
            font-size: Large;
            color: #FFFFFF;
          "
        >
        CX Monitoring Tool</v-col>
        <v-col align-self="center" cols="1">
          <v-img
            :src="require('@/assets/icon-configure.png')"
            class="ma-0"
            contain
            height="30"
          />
        </v-col>
      </v-row>
    </v-system-bar>

    <!-- Main Section -->
    <v-main v-if="auth.isLoggedIn"
      class="align-center justify-center"
      style="margin-top: 10px; min-height: 500px; min-width: 500px; background-color: #e5e5f0"
    >
      <v-container
        fluid
        fill-height
        class="d-flex flex-column pa-3 ma-0"
      >
        <!-- ScorecardInstanceSelect Component -->
        <v-row>
          <v-col cols="8">
            <ScorecardInstanceSelect/>
          </v-col>
          <v-col cols="4">
            <v-sheet v-if="app.activeuserType"
              rounded
              :elevation="3"
              style="border-radius:30px"
              class="mb-3"
            >
              <v-container>
                <v-row v-if="app.activeuserType=='reviewer'">
                  <v-col cols="8"
                    class="pb-0"
                  >
                    <div class="pa-0">
                      <div class="text-overline mb-1">
                        Average Journey Score
                      </div>
                      <div class="text-h4 mb-1">
                        AS IS
                      </div>
                      <div class="text-h2">
                        {{ app.t_average }}
                      </div>
                    </div>
                  </v-col>
                  <v-col  cols="4">
                    <v-img
                      v-if="app.gonogo"
                      :src="require('@/assets/go.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                    <v-img
                      v-if="!app.gonogo"
                      :src="require('@/assets/nogo.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="app.activeuserType=='admin'">
                  <v-col cols="3"
                    class="pb-0"
                  >
                    <div class="pa-0">
                      <div class="text-overline mb-1">
                        AVG Score Reviewer 1
                      </div>
                      <div class="text-h5 mb-1">
                        AS IS
                      </div>
                      <div class="text-h3">
                        {{ app.t_average }}
                      </div>
                    </div>
                  </v-col>
                  <v-col  cols="3">
                    <v-img
                      v-if="app.gonogo"
                      :src="require('@/assets/go.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                    <v-img
                      v-if="!app.gonogo"
                      :src="require('@/assets/nogo.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                  </v-col>
                  <v-col cols="3"
                    class="pb-0"
                  >
                    <div class="pa-0">
                      <div class="text-overline mb-1">
                        AVG Score Reviewer 2
                      </div>
                      <div class="text-h5 mb-1">
                        AS IS
                      </div>
                      <div class="text-h3">
                        {{ app.t_average2 }}
                      </div>
                    </div>
                  </v-col>
                  <v-col  cols="3">
                    <v-img
                      v-if="app.gonogo2"
                      :src="require('@/assets/go.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                    <v-img
                      v-if="!app.gonogo2"
                      :src="require('@/assets/nogo.svg')"
                      class="ma-0"
                      contain
                      height="219"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>

            <v-sheet
              rounded
              :elevation="3"
              style="border-radius:30px"
              class="mb-3"
            >
              <v-container>
                <v-row>
                  <v-col cols="12"
                    class="pb-0"
                  >
                    <v-combobox
                      v-model="app.activeuserId"
                      :items="app.userOptions"
                      label="Simulate User View"
                      variant="outlined"
                      density="compact"
                      clearable
                      @update:modelValue="updateDetails()"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ScorecardDetail v-if="app.activeuserType=='reviewer'"/>
            <ScorecardAdminDetail v-if="app.activeuserType=='admin'"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Footer Section -->
    <v-footer v-if="auth.isLoggedIn"
      class="bg-grey-lighten-1"
      style="max-height: 80px;"
    >
      <v-row justify="center" no-gutters>
        <v-col class="text-center mt-4" cols="12">
          {{ new Date().getFullYear() }} — <strong>PWC</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import ScorecardInstanceSelect from '@/components/ScorecardInstanceSelect.vue'
import ScorecardDetail from '@/components/ScorecardDetail.vue'
import ScorecardAdminDetail from '@/components/ScorecardAdminDetail.vue'
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import { onMounted, ref, watch } from 'vue';

export default {
  name: 'App',

  components: {
    ScorecardInstanceSelect,
    ScorecardDetail,
    ScorecardAdminDetail,
  },

  setup() {
    const auth = useAuthStore();
    const app = useAppStore();
    const formData = ref({
      username: '',
      password: ''
    });

    const login = async () => {
      try {
        await auth.login(app.baseUrl,formData);
        // Redirect to dashboard or another route
      } catch (error) {
        console.error('Login error:', error);
      }
    };

    const logout = () => {
      app.activeuserId=null;
      app.instanceId=null;
      app.activeuserType='';
      app.t_average=null;
      app.t_average2=null;
      app.gonogo=null;
      app.gonogo2=null;
      auth.logout();
      // Redirect to login page
    };

    const updateDetails = () => {
      //console.log('in updateDetails')
      if (app.activeuserId) {
        if (app.activeuserId.value == app.adminId) {
          app.activeuserType = "admin";
        } else if (app.activeuserId.value == app.reviewer1Id || app.activeuserId.value == app.reviewer2Id ) {
          app.activeuserType = "reviewer";
        } else {
          app.activeuserType = null;
        }
      }
    };

    watch(() => app, (newApp, oldApp) => {
      console.log('in watch instanceId');
      if (newApp.instanceId != oldApp.instanceId) {
        updateDetails();
      }
    });

    onMounted(() => {
      // Fetch initial data or perform any initial setup
    });

    return { 
      auth,
      app,
      login,
      logout,
      updateDetails,
      formData,
    };
  }
}
</script>